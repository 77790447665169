import React, { useEffect, useState } from "react";
import styles from "./home.module.scss";
import { Hero } from "../../components/Hero/hero.component";
import { Footer } from "../../components/Footer/footer.component";
import { client } from "../../sanityClient";

export const Home: React.FC<{ siteSettings: any }> = ({ siteSettings }) => {
  const [post, setPost] = useState<any | null>(null);

  useEffect(() => {
    async function fetchFrontPage() {
      const query = `*[_id == "frontPage"]{
        title,
        footerText,
        heroImage{
          asset->{
            _id,
            url
          }
        },
        content
      }[0]`; // Fetch the frontPage document by its _id

      try {
        const frontPageData = await (client as any).fetch(query);

        setPost(frontPageData); // Set the post state with the fetched data
      } catch (error) {
        console.error("Error fetching front page data:", error);
      }
    }

    fetchFrontPage(); // Call the fetch function when component mounts
  }, []); // Empty dependency array, since no slug is needed

  if (!post) return <p>Loading...</p>; // Display loading text while data is being fetched

  console.log("post", post);
  return (
    <div className={styles.homePage}>
      {/* Pass the fetched hero image URL to the Hero component */}
      <Hero
        colors={{
          primary: siteSettings?.primaryColor || "#ffffff",
          secondary: siteSettings?.secondaryColor || "#000000",
        }}
        title={post?.title}
        image={post?.heroImage?.asset.url || ""}
      />
      {/* Display other content here */}
      <Footer text={post?.footerText} />
    </div>
  );
};

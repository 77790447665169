import React, { useEffect, useState } from "react";
import { client } from "../../sanityClient";
import { useParams } from "react-router-dom";
import { APPLICATION_QUERY } from "../../queries/applicationQueries";
import { SceneSequence } from "../../sections/SceneSequence/SceneSequence.component";
import { SceneSection } from "../../sections/SceneSection/SceneSection.component";
import { EmailScreen } from "../../components/DramaticFullscreen/EmailScreen.component";
import { NameScreen } from "../../components/DramaticFullscreen/NameScreen.component";
import { MessageScreen } from "../../components/DramaticFullscreen/MessageScreen.component";
import { UploadsScreen } from "../../components/DramaticFullscreen/UploadsScreen.component";
import { AuditionScreen } from "../../components/DramaticFullscreen/AuditionScreen.component";
import { NumberScreen } from "../../components/DramaticFullscreen/NumberScreen.component";
import { DramaticFullscreen } from "../../components/DramaticFullscreen/DramaticFullscreen.component";
import { SummaryScreen } from "../../components/DramaticFullscreen/SummaryScreen.component";
import { CookieConsent } from "../../components/CookieConsent/CookieConsent.component";

import {
  saveToLocalStorage,
  getFromLocalStorage,
  fetchPostData,
} from "../../_utils";
import {
  handleFileSelection,
  handleVideoUploadWithThumbnail,
} from "../../_helpers";

const LOCAL_STORAGE_KEY = "applicationFormData";

export const Application: React.FC<{
  siteSettings: any;
  currentLang: string;
}> = () => {
  const { slug } = useParams();
  const [post, setPost] = useState<any>(null);
  const [sent, setSent] = useState<boolean>(false);
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, _] = useState<boolean>(false);
  const [useLocalStorage, setUseLocalStorage] = useState<boolean>(false);
  const [isDone, setIsDone] = useState<boolean>(false);
  const [skippedVideo, setSkippedVideo] = useState<boolean>(false);
  const [numberOfFiles, setNumberOfFiles] = useState<number>(0);
  const [uploadedFiles, setUploadedFiles] = useState<number>(0);

  // Initialize input fields with or without localStorage based on consent
  const [inputFields, setInputFields] = useState<any>(() => {
    if (localStorage.getItem("cookieConsent") === "true") {
      return (
        getFromLocalStorage(LOCAL_STORAGE_KEY) || {
          name: "",
          email: "",
          number: "",
          message: "",
          files: [],
          auditionVideo: null,
          videoThumbnail: null,
        }
      );
    } else {
      return {
        name: "",
        email: "",
        number: "",
        message: "",
        files: [],
        auditionVideo: null,
        videoThumbnail: null,
      };
    }
  });

  // Save inputFields to localStorage whenever they change, only if consent is given
  useEffect(() => {
    if (useLocalStorage) {
      saveToLocalStorage(LOCAL_STORAGE_KEY, inputFields);
    }
    console.log("Input fields updated:", inputFields);
  }, [inputFields, useLocalStorage]);

  useEffect(() => {
    if (!slug) {
      console.error("Slug is undefined, skipping the fetch.");
      return;
    }

    const fetchPost = async () => {
      try {
        const result = await fetchPostData(client, slug, APPLICATION_QUERY);
        setPost(result);
      } catch (error) {
        console.error("Error fetching post:", error);
      }
    };

    fetchPost();
  }, [slug]);

  const [allFilesReady, setAllFilesReady] = useState(false);

  useEffect(() => {
    // Check if files are loaded and other conditions are met
    if (
      inputFields.files.length === numberOfFiles &&
      (skippedVideo ||
        (inputFields.auditionVideo && inputFields.videoThumbnail))
    ) {
      setAllFilesReady(true);
    } else {
      setAllFilesReady(false);
    }
  }, [
    inputFields.files,
    inputFields.auditionVideo,
    inputFields.videoThumbnail,
    skippedVideo,
    numberOfFiles,
  ]);

  // Adjusted handleSubmitApplication to only set isSubmitting
  const handleSubmitApplication = () => {
    setIsSubmitting(true);
  };

  // useEffect to monitor allFilesReady and handle submission
  useEffect(() => {
    const submitApplication = async () => {
      if (!post) return; // Ensure post is defined

      console.log(
        "All assets have been successfully uploaded and are ready for submission."
      );

      try {
        const formData = {
          fullName: inputFields.name,
          email: inputFields.email,
          phone: inputFields.number,
          applicationText: inputFields.message,
          profileImage: inputFields.files[0],
          auditionVideo: inputFields.auditionVideo || "",
          videoThumbnail: inputFields.videoThumbnail || "",
          subscribeToMailchimp: true,
          listID: post.listID || "", // Fallback to empty string
        };

        const response = await fetch(
          "https://limitless-fjord-09502-12e51f39ac59.herokuapp.com/apply",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );

        const result = await response.json();
        if (response.ok) {
          setMessage(
            `Application for the role as "${post.title}" submitted successfully. An email confirmation will be sent to ${formData.email} shortly.`
          );
          setIsDone(true);
          setSent(true);
        } else {
          if (
            result.error ===
            `Failed to submit application. ${formData.email} is already a list member. Use PUT to insert or update list members.`
          ) {
            setMessage(
              `${formData.email} is already a list member for this role. We'll get back to you shortly.`
            );
          } else {
            setMessage(result.error);
            console.log("Error:", result.error);
          }
        }
      } catch (error) {
        setMessage(String(error));
        console.error("Error:", error);
      } finally {
        setIsSubmitting(false); // Reset isSubmitting
      }
    };

    if (isSubmitting && allFilesReady && post) {
      submitApplication();
    }
  }, [allFilesReady, isSubmitting, inputFields, post]);

  if (!post || !post.sceneSections) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <CookieConsent onConsent={setUseLocalStorage} />
      <SceneSequence>
        {post.sceneSections.map((section: any, sectionIndex: number) => (
          <SceneSection
            key={sectionIndex}
            time={section.time || 1}
            autoplay={section.autoplay}
            fade={section.fade}
          >
            {section.screens?.map((screen: any, screenIndex: number) => {
              switch (screen._type) {
                case "dramaticFullscreen":
                  return (
                    <DramaticFullscreen
                      key={screenIndex}
                      text={screen.text || ""}
                      theme={screen.theme || "white"}
                    />
                  );
                case "emailScreen":
                  return (
                    <EmailScreen
                      key={screenIndex}
                      theme={screen.theme || "white"}
                      text={screen.text || ""}
                      subText={screen.subText || ""}
                      metaData={{ inputFields }}
                      handleInput={(e) =>
                        setInputFields({ ...inputFields, email: e })
                      }
                    />
                  );
                case "nameScreen":
                  return (
                    <NameScreen
                      key={screenIndex}
                      theme={screen.theme || "white"}
                      text={screen.text || ""}
                      subText={screen.subText || ""}
                      metaData={{ inputFields }}
                      handleInput={(e) =>
                        setInputFields({ ...inputFields, name: e })
                      }
                    />
                  );
                case "summaryScreen":
                  return (
                    <SummaryScreen
                      key={screenIndex}
                      theme={screen.theme || "white"}
                      text={screen.text || ""}
                      subText={screen.subText || ""}
                      metaData={{
                        post,
                        inputFields,
                        loading,
                        sent,
                        isSubmitting,
                        uploadedFiles,
                      }}
                      message={message}
                      isDone={isDone}
                      handleSubmit={handleSubmitApplication}
                      stillUploading={isSubmitting}
                      skippedVideo={skippedVideo}
                    />
                  );
                case "auditionScreen":
                  return (
                    <AuditionScreen
                      key={screenIndex}
                      theme={screen.theme || "white"}
                      text={screen.text || ""}
                      subText={screen.subText || ""}
                      script={screen.script || ""}
                      soundcloudCode={screen.soundcloudCode || ""}
                      setSkipped={setSkippedVideo}
                      handleFileUpload={(file) =>
                        handleVideoUploadWithThumbnail(
                          client,
                          file,
                          setInputFields
                        )
                      }
                      metaData={{ post, inputFields }}
                    />
                  );
                case "messageScreen":
                  return (
                    <MessageScreen
                      key={screenIndex}
                      theme={screen.theme || "white"}
                      text={screen.text || ""}
                      subText={screen.subText || ""}
                      metaData={{ inputFields }}
                      handleInput={(e) =>
                        setInputFields({ ...inputFields, message: e })
                      }
                    />
                  );
                case "numberScreen":
                  return (
                    <NumberScreen
                      key={screenIndex}
                      theme={screen.theme || "white"}
                      text={screen.text || ""}
                      subText={screen.subText || ""}
                      metaData={{ inputFields }}
                      handleInput={(e) =>
                        setInputFields({ ...inputFields, number: e })
                      }
                    />
                  );
                case "uploadScreen":
                  return (
                    <UploadsScreen
                      key={screenIndex}
                      theme={screen.theme || "white"}
                      text={screen.text || ""}
                      subText={screen.subText || ""}
                      metaData={{ post, inputFields, setInputFields }}
                      setNumberOfFiles={setNumberOfFiles}
                      setUploadedFiles={setUploadedFiles}
                      uploadedFiles={uploadedFiles}
                      handleInput={(e) =>
                        handleFileSelection(client, e, setInputFields)
                      }
                    />
                  );
                default:
                  return null;
              }
            })}
          </SceneSection>
        ))}
      </SceneSequence>
    </div>
  );
};

import { uploadAsset } from "./_utils";

// Utility to fetch localized text based on the current language
export const getLocalizedText = (
  urlArray: { _key: string; value: string }[] | undefined,
  currentLang: string,
  fallback: string = ""
): string => {
  return urlArray?.find((i) => i._key === currentLang)?.value || fallback;
};

// Helper to handle file selection and upload multiple images
export const handleFileSelection = async (
  client: any,
  files: File[],
  setInputFields: Function
) => {
  try {
    const uploadedFiles = await Promise.all(
      files.map((file) => uploadAsset(client, "image", file))
    );

    console.log("Uploaded files:", uploadedFiles);

    setInputFields((prevFields: any) => {
      console.log("Previous files in state:", prevFields.files);
      console.log("Uploaded files to add:", uploadedFiles);

      return {
        ...prevFields,
        files: [...(prevFields.files || []), ...uploadedFiles],
      };
    });
  } catch (error) {
    console.error("Error uploading selected files:", error);
    throw error;
  }
};

// Upload video file and generate a thumbnail
export const handleVideoUploadWithThumbnail = async (
  client: any,
  file: File,
  setInputFields: Function
) => {
  try {
    const videoUpload = await client.assets.upload("file", file, {
      contentType: file.type,
      filename: file.name,
    });

    const thumbnailUpload = await extractAndUploadThumbnail(client, file);
    const thumbnailUrl =
      typeof thumbnailUpload === "string"
        ? thumbnailUpload
        : thumbnailUpload?.url || thumbnailUpload?.path || null;

    if (thumbnailUrl) {
      setInputFields((prevFields: any) => ({
        ...prevFields,
        auditionVideo: videoUpload.url,
        videoThumbnail: thumbnailUrl,
      }));
    } else {
      throw new Error("Thumbnail upload failed");
    }
  } catch (error) {
    console.error("Error uploading video or thumbnail:", error);
  }
};

// Extract a thumbnail from a video and upload it as an image
const extractAndUploadThumbnail = async (client: any, file: File) => {
  const videoElement = document.createElement("video");
  const videoPreview = URL.createObjectURL(file);
  videoElement.src = videoPreview;
  videoElement.preload = "metadata";

  return new Promise<any>((resolve, reject) => {
    videoElement.onloadedmetadata = () => {
      videoElement.currentTime = 0.001; // seek to the first frame
    };

    videoElement.onseeked = () => {
      setTimeout(() => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        if (!ctx) {
          reject("Failed to get canvas context");
          return;
        }

        // Calculate dimensions for 16:9 aspect ratio
        const originalWidth = videoElement.videoWidth;
        const originalHeight = videoElement.videoHeight;
        const targetWidth = originalWidth;
        const targetHeight = (originalWidth * 9) / 16;

        // Crop vertically if necessary
        const cropY = (originalHeight - targetHeight) / 2;

        canvas.width = targetWidth;
        canvas.height = targetHeight;

        try {
          // Draw the cropped video frame onto the canvas
          ctx.drawImage(
            videoElement,
            0,
            cropY,
            targetWidth,
            targetHeight,
            0,
            0,
            canvas.width,
            canvas.height
          );
        } catch (drawError) {
          reject("Error drawing video on canvas");
          return;
        }

        // Convert canvas to a blob and upload
        canvas.toBlob(async (blob) => {
          if (blob) {
            const thumbnailFile = new File(
              [blob],
              `${file.name}-thumbnail.jpg`,
              { type: "image/jpeg" }
            );

            try {
              const thumbnailUpload = await uploadAsset(
                client,
                "image",
                thumbnailFile
              );
              resolve(thumbnailUpload);
            } catch (uploadError) {
              reject("Thumbnail upload failed");
            }
          } else {
            reject("Thumbnail extraction failed");
          }
        }, "image/jpeg");
      }, 100);
    };

    videoElement.onerror = (e) => {
      reject("Error loading video metadata");
    };
  });
};

// Validate name input
export const validateName = (
  input: string,
  setInvalid: (invalid: boolean) => void
) => {
  if (input.trim().length > 0) {
    setInvalid(false);
    return true;
  } else {
    setInvalid(true);
    return false;
  }
};

interface DateWithOrdinal {
  (dateString: string): string;
}

interface AddOrdinal {
  (day: number): string;
}

export const formatDateWithOrdinal: DateWithOrdinal = (dateString) => {
  const addOrdinal: AddOrdinal = (day) => {
    if (day > 3 && day < 21) return `${day}th`;
    switch (day % 10) {
      case 1:
        return `${day}st`;
      case 2:
        return `${day}nd`;
      case 3:
        return `${day}rd`;
      default:
        return `${day}th`;
    }
  };

  const date = new Date(dateString);
  const monthNames: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const month: string = monthNames[date.getMonth()];
  const day: number = date.getDate();

  return `${month} ${addOrdinal(day)}`;
};

import React from "react";
import styles from "./shopNavbar.module.scss";
import { Link } from "react-router-dom";

export const ShopNavbar = () => {
  const handleBurgerClick = () => {
    const burger = document.querySelector(`.${styles.burger}`);
    burger?.classList.toggle(styles.active);

    const links = document.querySelector(`.${styles.links}`);
    links?.classList.toggle(styles.linksActive);
  };

  return (
    <div className={styles.shopNavbar}>
      <div className={styles.burger} onClick={handleBurgerClick}>
        <div className={styles.line1}></div>
        <div className={styles.line2}></div>
        <div className={styles.line3}></div>
      </div>
      <div className={styles.navbarInner}>
        <div>
          {window.location.pathname !== "/shop" ? (
            <Link to="/shop">⬅ Go back</Link>
          ) : (
            ""
          )}
        </div>
        <div className={styles.navbarLogo}>
          <img src="/images/logo/Light_1024px.png" alt="" />
        </div>
        <div className={styles.links}>
          {/* <div className={styles.navbarItem}>
            <Link to="/shop">shop</Link>
          </div> */}
          <div className={styles.navbarItem}>
            <Link to="https://tr.ee/v4CKFeaECI">
              <img
                src="https://theme.zdassets.com/theme_assets/795717/36caa0817d2c07fd46cf610c3aa4b1646254a1d4.png"
                alt=""
              />
            </Link>
          </div>

          <div className={styles.navbarItem}>
            <Link to="/shop">
              <img
                src="https://png.pngtree.com/png-vector/20190329/ourlarge/pngtree-vector-shopping-bag-icon-png-image_889429.jpg"
                alt=""
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

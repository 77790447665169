// src/queries/epkQueries.ts

export const EPK_QUERY = `*[_type == "epk" && slug.current == $slug][0]{
    _id,
    mainColor,
    secondaryColor,
    tertiaryColor,
    songTitle,
    youtubeLink,
    soundcloudLink,
    platforms,
    biography,
    releaseDate,
    hasIntroText,
    hasSongDescription,
    hasYoutubeLink,
    hasInstagramWidget,
    introText,
    songDescription,
    pressReleaseEn[]{
      ...,
      children[]{
        text,
        _type,
        marks
      },
      _type
    },
    pressReleaseNo[]{
      ...,
      children[]{
        text,
        _type,
        marks
      },
      _type
    },
    pressReleaseEs[]{
      ...,
      children[]{
        text,
        _type,
        marks
      },
      _type
    },
    headerImage {
      asset -> { url }
    },
    albumCover {
      asset -> { url }
    },
    lowQualityBlur {
      asset -> { url }
    },
    profileImage {
      asset -> { url }
    },
    spotifyLink,
    fourthPressReleaseImage {
      asset -> { url }
    },
    publicityMedia[]{
      asset -> { url },
      _type
    }, 
    instagramPost {
      images[]{
      asset -> { url }
    },
      description
    },
    slug
  }`;

import React, { useEffect, useState } from "react";
import styles from "./Dayplan.module.scss";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import dayplanData from "../../dayplan.json"; // Import the local JSON file
import MailchimpForm from "../../forms/ActorForm/ActorForm.component";

const Loading = () => <div>Loading...</div>;

const BeforeStart = ({
  title,
  timeLeft,
}: {
  title: string;
  timeLeft: string;
}) => (
  <div className={styles.beforeStart}>
    <p>Innspilling starter om</p>
    <h2>{timeLeft}</h2>
    <a>Se full dagsplan her</a>
    <br />
    <img
      src="https://static-00.iconduck.com/assets.00/address-icon-1620x2048-3s4bnjam.png"
      alt=""
    />
    <p>Se lokasjon</p>
  </div>
);

const SceneDescription = ({ description }: { description: string[] }) => (
  <ul>
    {description.map((desc, index) => (
      <li key={index}>
        <p>{desc}</p>
      </li>
    ))}
  </ul>
);

const InProgress = ({
  currentScene,
  nextSceneTitle,
  timeLeft,
  currentIndex,
  totalScenes,
}: {
  currentScene: any;
  nextSceneTitle: string;
  timeLeft: string;
  currentIndex: number;
  totalScenes: number;
}) => (
  <>
    <div className={styles.pill}>
      <p>{currentScene.status}</p>
    </div>
    <h4 className={styles.header}>{currentScene.scene.title}</h4>
    <SceneDescription description={currentScene.scene.description} />
    <div className={styles.bottomSection}>
      <p>Neste på programmet</p>
      <>
        <h5>{nextSceneTitle || "Ingen flere scener"}</h5>
        <div className={styles.timer}>
          <p>
            {currentIndex + 1 < totalScenes
              ? "Starter om "
              : "Eventet er ferdig om "}
            {timeLeft}
          </p>
        </div>
      </>
    </div>
  </>
);

const Finished = () => (
  <div className={styles.finished}>
    <h4>Tusen takk</h4>
    <p>
      Jeg vil gjerne takke alle for deres oppmerksomhet og innsats i dette
      prosjektet. Jeg gleder meg utrolig mye til å vise frem det ferdige
      resultatet, og håper jeg får jobbe med dere igjen. For å holde dere
      oppdatert på prosjektet og andre kommende prosjekter, kan dere besøke
      [sett inn lenke her]. Jeg ser frem til å dele mer med dere!
    </p>
    <br />
    <p>
      Bare å sende meg en melding på <a href="#s"> instagram </a>
      eller på
      <a href="#s"> telefon </a> hvis du lurer på noe!
    </p>

    <MailchimpForm />
  </div>
);

export const Dayplan: React.FC<{ currentLang: any }> = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);
  const [isFinished, setIsFinished] = useState(false); // Track if all scenes have passed
  const [firstSceneStarted, setFirstSceneStarted] = useState(false);

  // Boolean to control whether to use real time or dummy time
  const useDummyTime = false;

  // State for dummy time (initialize it to the desired start time)
  const [dummyTime, setDummyTime] = useState(
    new Date("2024-10-03T09:59:59").getTime()
  );

  // Function to get the current time, either real or dummy
  const getCurrentTime = () => {
    return useDummyTime ? dummyTime : new Date().getTime();
  };

  // Keep the 'Z' in the timestamps and treat them as UTC
  const firstSceneStartTime = new Date(
    dayplanData.projects[0].scenes[0].scene.timestampStart
  ).getTime();

  useEffect(() => {
    const checkFirstSceneStarted = () => {
      const currentTime = getCurrentTime(); // Use either real or dummy time

      if (currentTime >= firstSceneStartTime) {
        setFirstSceneStarted(true);
      } else {
        setFirstSceneStarted(false);
        // Calculate time left until the first scene starts
        setTimeLeft(firstSceneStartTime - currentTime);
      }
    };

    // Run the check immediately
    checkFirstSceneStarted();

    // Update time and check scene start every second
    const intervalId = setInterval(() => {
      checkFirstSceneStarted(); // Check if the first scene has started

      // Increment dummy time by 1 second if using dummy time
      if (useDummyTime) {
        setDummyTime((prevTime) => prevTime + 1000); // Increment by 1 second (1000 milliseconds)
      }
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [firstSceneStartTime, dummyTime, useDummyTime]);

  useEffect(() => {
    const updateCountdown = () => {
      const currentTime = getCurrentTime(); // Use either real or dummy time
      const currentScene = dayplanData.projects[0].scenes[currentIndex];
      const timestampStart = new Date(
        currentScene.scene.timestampStart
      ).getTime();
      const timestampEnd = new Date(currentScene.scene.timestampEnd).getTime();

      if (currentTime >= timestampEnd) {
        if (currentIndex === dayplanData.projects[0].scenes.length - 1) {
          setIsFinished(true); // No more scenes
          return;
        } else {
          // Move to the next scene if the current one has finished
          setCurrentIndex(currentIndex + 1);
        }
      } else {
        // Calculate time left until the end of the current scene
        setTimeLeft(timestampEnd - currentTime);
      }
    };

    // Start the initial countdown only if the first scene has started
    if (firstSceneStarted) {
      updateCountdown();
    }

    // Update countdown every second if the first scene has started
    const intervalId = setInterval(() => {
      if (firstSceneStarted) {
        updateCountdown();
      }
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [currentIndex, firstSceneStarted]);

  // Format timeLeft (in milliseconds) into HH:MM:SS
  const formatTime = (milliseconds: number) => {
    if (isNaN(milliseconds) || milliseconds < 0) {
      return "00:00:00"; // Return a default value if the milliseconds are not valid
    }

    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(seconds).padStart(2, "0")}`;
  };

  // Determine the current status
  const getStatus = () => {
    if (isFinished) return "finished";
    if (!firstSceneStarted) return "beforeStart";
    return "inProgress";
  };

  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  if (!dayplanData) {
    return <Loading />;
  }

  const currentScene = dayplanData.projects[0].scenes[currentIndex]; // Replace '0' with dynamic project index if needed
  const nextSceneTitle =
    currentIndex + 1 < dayplanData.projects[0].scenes.length
      ? dayplanData.projects[0].scenes[currentIndex + 1]?.scene.title
      : "";

  // Render based on the current status
  const status = getStatus();

  return (
    <div className={styles.dayplan}>
      {status === "beforeStart" && (
        <BeforeStart
          title={currentScene.scene.title}
          timeLeft={formatTime(timeLeft)}
        />
      )}

      {status === "inProgress" && (
        <InProgress
          currentScene={currentScene}
          nextSceneTitle={nextSceneTitle}
          timeLeft={formatTime(timeLeft)}
          currentIndex={currentIndex}
          totalScenes={dayplanData.projects[0].scenes.length}
        />
      )}

      {status === "finished" && <Finished />}
    </div>
  );
};

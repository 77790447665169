import { useEffect, useState } from "react";

export const RegularBlinker: React.FC<{
  speed: number;
  children: React.ReactNode;
}> = ({ children, speed = 200 }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setVisible((prev) => !prev);
    }, speed); // Adjust the interval time as needed (in milliseconds)

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [speed]);

  return (
    <span
      style={{
        opacity: visible ? "1" : "0",
        display: "flex",
        flexDirection: "column",

        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {children}
    </span>
  );
};

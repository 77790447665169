import React, { useState } from "react";
import styles from "./SignupForm.module.scss";
import { useTranslation } from "react-i18next";

export const SignupForm: React.FC<{
  compact?: boolean;
  subscribeButtonText?: string;
  inputRef?: any;
}> = ({ subscribeButtonText = "subscribe", compact = false, inputRef }) => {
  const [email, setEmail] = useState("");
  const [checked, setChecked] = useState(false);
  const [successfullySubscribed, setSuccessfullySubscribed] = useState(false);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const isEmailValid = (email: string) => {
    const regex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return regex.test(email);
  };

  const [message, setMessage] = useState("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isEmailValid(email)) {
      setMessage("Invalid email address");
      return;
    }

    // Start loading
    setLoading(true);

    // Prepare the data
    const formData = {
      email,
    };

    try {
      const res = await fetch(
        "https://limitless-fjord-09502-12e51f39ac59.herokuapp.com/subscribe",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
          mode: "cors",
        }
      );

      if (!res.ok) {
        const errorData = await res.json(); // Log the raw response body in case of failure
        console.error("Error response:", errorData);
        setMessage(`${errorData.error}`);

        return;
      }

      const data = await res.json();
      setSuccessfullySubscribed(true); // Set success state
      setMessage("Subscription successful!");
      setEmail("");
    } catch (error) {
      setMessage("An error occurred while subscribing.");
      console.error("Fetch error:", error); // Log any fetch error
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div className={`${styles.signupForm} ${compact ? styles.compact : ""}`}>
      <div className={styles.mcEmbedSignup}>
        {!compact && (
          <h3>{!successfullySubscribed ? t("join") : `${t("thank_you")}.`}</h3>
        )}
        {!compact && (
          <p>
            {!successfullySubscribed
              ? t("get_latest_news")
              : t("subscribe_success")}
          </p>
        )}
        <div
          style={{ display: `${!successfullySubscribed ? "block" : "none"}` }}
        >
          <form
            onSubmit={handleSubmit}
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="validate"
            target="_self"
            noValidate
          >
            <div className={styles.mcFieldGroup}>
              <input
                type="email"
                name="EMAIL"
                className={styles.requiredEmail}
                id="mce-EMAIL"
                required
                placeholder={t("e-mail")}
                value={email}
                ref={inputRef}
                onChange={(e) => setEmail(e.target.value)}
              />
              {!compact && (
                <div
                  className={styles.agreement}
                  onClick={() => setChecked(!checked)}
                >
                  <div className={styles.checkbox}>
                    <div
                      className={`${styles.checkboxInner} ${
                        checked ? styles.active : ""
                      }`}
                    ></div>
                  </div>
                  <p>
                    {t("consent_sending")}
                    <br />
                    <br />
                    {t("consent_age")}
                  </p>
                </div>
              )}
              <input
                type="submit"
                name="subscribe"
                id="mc-embedded-subscribe"
                className={`${styles.button} ${
                  loading ? styles.animatedBackground : ""
                }`}
                value={subscribeButtonText}
                disabled={loading || (compact ? !email : !email || !checked)} // Disable when loading
              />
            </div>
          </form>
        </div>
        {successfullySubscribed && !compact && (
          <div>
            <a
              href="https://tr.ee/v4CKFeaECI"
              className={styles.button}
              id="mc-embedded-subscribe"
            >
              Listen to Indieclimb
            </a>
            <p className={styles.follow}>{t("follow")}</p>
            <div className={styles.socialMedias}>
              <a
                href="https://www.instagram.com/indieclimb/"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/images/icons/INSTAGRAM.png" alt="Instagram" />
              </a>
              <a
                href="https://www.facebook.com/indieclimb"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/images/icons/FACEBOOK.png" alt="Facebook" />
              </a>
              <a
                href="https://www.youtube.com/@Indieclimb"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/images/icons/YOUTUBE.png" alt="Youtube" />
              </a>
            </div>
          </div>
        )}
        {message && (
          <div
            className={`${styles.message} ${
              successfullySubscribed ? styles.success : ""
            } `}
          >
            <p>{message}</p>
          </div>
        )}
      </div>
    </div>
  );
};

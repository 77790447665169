export const SONG_REGISTER_QUERY = `*[_type == "songRegister" && slug.current == $slug][0]{
    _id,
    title,
    subtext,
    bgGIF{
        asset->{
          url
        }
    },
    coverImage{
        asset->{
          url
        }
    },
    releaseDate,
    link,
    callToAction,
    placeholder,
    terms,
    songPreview{
        asset->{
          url
        }    
    }
  }`;

import React, { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { client } from "../../sanityClient";
import { APPLICANTRESULTS_QUERY } from "../../queries/applicantResultQueries";
import { useTranslation } from "react-i18next";
import styles from "./DownloadPage.module.scss";

export const DownloadPage: React.FC<{
  siteSettings: any;
  currentLang: string;
}> = ({ siteSettings, currentLang }) => {
  const { slug } = useParams();
  const [post, setPost] = useState<any>(null);
  useEffect(() => {
    const fetchPost = async () => {
      try {
        const result = await (client as any).fetch(APPLICANTRESULTS_QUERY, {
          slug,
        });

        setPost(result);

        console.log("post", result);
      } catch (error) {
        console.error("Error fetching post:", error);
      }
    };

    fetchPost();
  }, [slug]);
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div className={styles.downloadPage}>
      <h1>HELLO</h1>
    </div>
  );
};

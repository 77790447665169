import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { client } from "../../sanityClient";
import styles from "./ProjectPage.module.scss";
import { EPKPost } from "./ProjectPage.interface";
import PressReleaseSection from "../../sections/PressReleaseSection/PressReleaseSection.component";
import BioSection from "../../sections/BioSection/BioSection.component";
import SongSection from "../../sections/SongSection/SongSection.component";
import InstagramPost from "../../components/InstagramPost/InstagramPost.component";
import { ImagesSection } from "../../components/PublicityPhotos/PublicityPhotos.component";
import { useTranslation } from "react-i18next";
import { SocialFooterSection } from "../../sections/SocialFooterSection/SocialFooterSection.component";
import { EPK_QUERY } from "../../queries/epkQueries";
import { getLocalizedText } from "../../_helpers";

const ProjectPage: React.FC<{ currentLang: any }> = ({ currentLang }) => {
  const { t } = useTranslation();
  const { slug } = useParams();
  const [post, setPost] = useState<EPKPost | null>(null);

  const pressReleaseKey = `pressRelease${currentLang?.charAt(0).toUpperCase() + currentLang?.slice(1)}`;
  useEffect(() => {
    const fetchPost = async () => {
      try {
        const result = await (client as any).fetch(EPK_QUERY, { slug });
        setPost(result);
      } catch (error) {
        console.error("Error fetching post:", error);
      }
    };

    fetchPost();
  }, [slug]);

  useEffect(() => {
    if (post?.mainColor) {
      document.documentElement.style.setProperty(
        "--main-color",
        post.mainColor.value
      );
    }
  }, []);

  if (!post) return <p>Loading...</p>;

  return (
    <div className={styles.project}>
      {/* HEADER */}
      <div className={styles.header}>
        {post.headerImage?.asset?.url ? (
          <div className={styles.headerImage}>
            <img src={post.headerImage.asset.url} alt="" />
          </div>
        ) : (
          <img src="https://via.placeholder.com/1200x400" alt="" />
        )}
        <div className={styles.headerLogo}>
          <img
            src="https://cdn.sanity.io/images/m4jodx2s/production/3c98565d56f7113cb06a5317b6bff2d1057e4833-1920x1920.jpg"
            alt="Header Logo"
          />
        </div>
      </div>
      {post?.hasIntroText && (
        <div className={styles.wrapper}>
          {/* INTRO */}

          <div className={styles.shocker}>
            <h1>{post.songTitle}</h1>
            <p>{getLocalizedText(post?.introText, currentLang)}</p>
          </div>

          {post?.hasYoutubeLink && (
            <div className={styles.shockerVideo}>
              <iframe
                width="900px"
                height="600px"
                src={post.youtubeLink}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          )}
        </div>
      )}

      {/* SONG SECTION */}
      <SongSection
        mainColor={post?.mainColor?.value}
        soundCloudUrl={post?.soundcloudLink}
        releaseDate={post?.releaseDate}
        albumCover={post?.albumCover?.asset.url}
        lowQualityBlur={post.lowQualityBlur?.asset.url}
        description={getLocalizedText(post?.songDescription, currentLang)}
      />
      {/* BIO SECTION */}
      <h1 className={styles.sectionHeader}>{t("biography")}</h1>
      <BioSection
        profileImage={post.profileImage?.asset.url}
        biographyText={getLocalizedText(post?.biography, currentLang)} // or fetch dynamically
      />

      {/* PRESS RELEASE SECTION */}
      <h1 className={styles.sectionHeader}>{t("press_release")}</h1>

      <PressReleaseSection
        pressRelease={post ? (post as any)[pressReleaseKey] : undefined}
      />

      {post?.hasInstagramWidget && (
        <>
          <h1 className={styles.sectionHeader}>{t("insta_preview")}</h1>
          <InstagramPost
            contentImages={post.instagramPost?.images} // Pass array of images
            // @ts-ignore
            description={post.instagramPost?.description} // Pass description
          />
        </>
      )}

      <h1 className={styles.sectionHeader}>{t("publicity_photos")}</h1>
      <ImagesSection
        title="Publicity Media"
        download={{ image: "Download Image", video: "Download Video" }}
        colors={[
          post?.mainColor?.value,
          post?.secondaryColor?.value,
          post?.tertiaryColor?.value,
        ]}
        currentProject={post.slug.current}
        images={post.publicityMedia?.map((media) => ({
          asset: { url: media.asset.url },
          _type: media._type, // Either image or video
        }))}
      />
      <SocialFooterSection />
    </div>
  );
};

export default ProjectPage;

import React from "react";
import styles from "./footer.module.scss";
import { useTranslation } from "react-i18next";

export const Footer: React.FC<{
  text?: {
    text: string;
    url: string;
  };
}> = ({ text }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.footer}>
      <h2>
        <a href={text?.url}>{t("get_a_glimpse")}</a>
      </h2>
      <p>© 2024 INDIECLIMB RECORDS</p>
    </div>
  );
};

import React from "react";
import styles from "./BioSection.module.scss";
import { useTranslation } from "react-i18next";

interface BioSectionProps {
  profileImage: string;
  biographyText: string;
}

const BioSection: React.FC<BioSectionProps> = ({
  profileImage,
  biographyText,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.bioSection}>
      <div className={styles.bioImage}>
        {profileImage && <img src={profileImage} alt="Profile" />}
      </div>
      <div className={styles.bioText}>
        <p>{biographyText ? biographyText : t("bio_text")}</p>
      </div>
    </div>
  );
};

export default BioSection;

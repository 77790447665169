import React, { useEffect, useState } from "react";
import styles from "./AdPage.module.scss";

import { ScrollyTellImage } from "../../../components/ScrollyTellImage/ScrollyTellImage.component";
import { FullscreenText } from "../../../components/FullscreenText/FullscreenText.component";
import { QuoteScreen } from "../../../components/QuoteScreen/QuoteScreen.component";
import { Actionscreen } from "../../../components/ActionScreen/Actionscreen.component";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import ScrollLine from "../../../components/ScrollLine/ScrollLine.component";
import { client } from "../../../sanityClient";
import { ADS_QUERY } from "../../../queries/adQueries";
import { getLocalizedText } from "../../../_helpers";

// Define the type for the `image_and_text` array
interface ImageAndText {
  _type: string;
  _key: string;
  asset?: {
    url: string;
  };
  title?: {
    value: string;
  };
  url?: { _key: string; value: string }[]; // You can optionally add the `children` type only for blocks with text content
  children?: { text: string }[];
}

export const AdPage: React.FC<{ currentLang: any }> = ({ currentLang }) => {
  const { slug } = useParams();
  const [post, setPost] = useState<any>(null);
  useEffect(() => {
    const fetchPost = async () => {
      try {
        const result = await (client as any).fetch(ADS_QUERY, { slug });

        setPost(result);
      } catch (error) {
        console.error("Error fetching post:", error);
      }
    };

    fetchPost();
  }, [slug]);

  //   const pixelCode = `

  //   <!-- Meta Pixel Code -->
  //   !function(f,b,e,v,n,t,s)
  //   {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  //   n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  //   if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  //   n.queue=[];t=b.createElement(e);t.async=!0;
  //   t.src=v;s=b.getElementsByTagName(e)[0];
  //   s.parentNode.insertBefore(t,s)}(window, document,'script',
  //   'https://connect.facebook.net/en_US/fbevents.js');
  //   fbq('init', '7585404441481822');
  //   fbq('track', 'PageView');
  // ;
  // <!-- End Meta Pixel Code -->`;
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Check if the pathname is just '/' or effectively empty
    if (location.pathname === "/let-me-go") {
      navigate("/en/let-me-go");
    }
  }, [navigate, location.pathname]);

  function handleImageError(
    event: React.SyntheticEvent<HTMLImageElement>,
    defaultImage: string
  ) {
    event.currentTarget.src = `/images/Awards/${defaultImage}`;
  }

  if (!post) return <p>Loading...</p>;
  return (
    <div className={styles.letMeGoPage}>
      <Helmet>
        <title>Indieclimb - {post?.title.toUpperCase()}</title>
        <meta name="robots" content="noindex" />
        {/* <script>{pixelCode}</script> */}
      </Helmet>
      <ScrollLine />
      <ScrollyTellImage
        svh={true}
        imgUrl={post.bannerImage?.asset?.url}
        positionHorizontal={40}
        washedOut
        fadein={false}
      >
        <div className={styles.topBanner}>
          <div className={styles.titleName}>
            <div className={styles.center}>
              <p>{t("directed_and_produced_by")}</p>
              <h1>{post.title}</h1>
            </div>
          </div>
          <div className={styles.awards}>
            <img
              style={{ opacity: 0, transition: "opacity 1s" }}
              onLoad={(e) => {
                e.currentTarget.style.opacity = "1";
              }}
              src={`/images/Awards/${currentLang}_roma.png`}
              alt="Award"
              onError={(e) => handleImageError(e, "en_roma.png")}
            />
            <img
              style={{ opacity: 0, transition: "opacity 1s" }}
              onLoad={(e) => {
                e.currentTarget.style.opacity = "1";
              }}
              src={`/images/Awards/${currentLang}_budapest.png`}
              alt="Award"
              onError={(e) => handleImageError(e, "en_budapest.png")}
            />
          </div>
          <div
            className={styles.scrollDown}
            onClick={() => {
              window.scrollBy({
                top: window.innerHeight, // Vertical scroll increments by the height of the viewport
                left: 0, // Horizontal scroll increments by 100 pixels
                behavior: "smooth", // Smoothly animate the scroll
              });
            }}
          >
            <p>{t("scroll_for_more")}</p>
            <img
              src="https://www.seekpng.com/png/full/241-2418356_arrow-white-white-scroll-down-gif.png"
              alt=""
            />
          </div>
        </div>
      </ScrollyTellImage>

      {/* <FullscreenText text={t("debut_single_info")} />

      <ScrollyTellImage
        imgUrl="/images/Scrollytell/2LET ME GO – 4K.04_44_23_01.Still235 copy.jpg"
        panorama
      /> */}

      {post.images_and_text?.map((item: ImageAndText, index: number) => {
        if (item._type === "image") {
          // For image items
          const imageUrl = item?.asset?.url;
          return (
            <ScrollyTellImage
              key={index}
              imgUrl={imageUrl}
              panorama
              position="center"
            />
          );
        } else if (item._type === "title") {
          // Handle titles with internationalizedArray

          return (
            <FullscreenText
              key={index}
              text={getLocalizedText(item.url, currentLang)}
            />
          );
        }

        return null; // If no matching _type is found
      })}

      {/* 
      <FullscreenText text={t("international_recognition")} />

      <ScrollyTellImage
        imgUrl="/images/Scrollytell/LET ME GO – 4K.04_10_45_15.Still246 copy.jpg"
        panorama
        position="center"
      />

      <FullscreenText text={t("artist_announcement")} />

      <ScrollyTellImage
        imgUrl="/images/Scrollytell/LET ME GO – 4K.04_21_33_14.Still250 copy.jpg"
        panorama
        position="bottom"
      />

      <FullscreenText text={t("outro")} /> */}

      <ScrollyTellImage
        imgUrl={post?.quotesSection?.backgroundImage.asset.url}
        washedOut
      >
        <QuoteScreen
          header={t("what_the_critics_say")}
          quotes={
            post?.quotesSection?.quotes?.length
              ? post.quotesSection.quotes.map((quoteItem: any) => ({
                  author: quoteItem.author,
                  quote: getLocalizedText(quoteItem.text, currentLang),
                }))
              : []
          }
        />
      </ScrollyTellImage>
      <ScrollyTellImage
        imgUrl={post.footerImage?.asset?.url}
        washedOut
        positionHorizontal={82}
        fadein={false}
      >
        <Actionscreen
          translations={{ watch: t("watch"), listen: t("listen_now") }}
          cover={post?.albumCover?.asset?.url}
          videoLink={post?.videoLink}
        />
      </ScrollyTellImage>
    </div>
  );
};

import React, { useEffect, useRef, useState } from "react";
import styles from "./SongSection.module.scss";
import { useTranslation } from "react-i18next";

declare global {
  interface Window {
    SC: typeof SC;
  }
}

declare namespace SC {
  interface Widget {
    bind(event: string, callback: () => void): void;
    play(): void;
    pause(): void;
  }

  namespace Widget {
    enum Events {
      PLAY = "play",
      PAUSE = "pause",
      FINISH = "finish",
    }
  }

  function Widget(iframeElement: HTMLIFrameElement): Widget;
}

interface SongSectionProps {
  mainColor: string;
  releaseDate: string;
  albumCover: string;
  lowQualityBlur: string;
  description: string;
  soundCloudUrl: string;
}

const SongSection: React.FC<SongSectionProps> = ({
  mainColor,
  releaseDate,
  albumCover,
  lowQualityBlur,
  description,
  soundCloudUrl,
}) => {
  const { t } = useTranslation();
  const [isPlaying, setIsPlaying] = useState(false);
  const widgetRef = useRef<SC.Widget | null>(null);
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://w.soundcloud.com/player/api.js";
    script.async = true;

    script.onload = () => {
      if (iframeRef.current && window.SC) {
        widgetRef.current = SC.Widget(iframeRef.current);

        // Bind events
        widgetRef.current.bind(SC.Widget.Events.PLAY, () => setIsPlaying(true));
        widgetRef.current.bind(SC.Widget.Events.PAUSE, () =>
          setIsPlaying(false)
        );
        widgetRef.current.bind(SC.Widget.Events.FINISH, () =>
          setIsPlaying(false)
        );
      }
    };

    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleButtonClick = () => {
    if (widgetRef.current) {
      if (isPlaying) {
        widgetRef.current.pause();
      } else {
        widgetRef.current.play();
      }
    }
  };

  return (
    <div className={styles.songSection}>
      <div
        className={styles.songSectionTextWrapper}
        style={{ backgroundColor: mainColor }}
      >
        <div className={styles.songSectionText}>
          <h1>{t("the_song")}</h1>
          <p className={styles.releaseDate}>
            {t("out_on_all_platforms")}:{" "}
            {new Date(releaseDate)
              .toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })
              .replace(/\//g, ".")}
          </p>
          <p>{description}</p>
        </div>
      </div>
      <div
        className={styles.songSectionCover}
        style={{ backgroundColor: mainColor }}
      >
        <button
          className={`${styles.neoButton} ${!isPlaying ? styles.play : ""}`}
          style={
            {
              "--border-color": mainColor,
            } as React.CSSProperties
          }
          onClick={handleButtonClick}
        ></button>
        <div className={styles.blurryBackground}>
          {lowQualityBlur && <img src={lowQualityBlur} alt="Album Cover" />}
        </div>
        <div
          className={`${styles.spinningRecord} ${isPlaying ? styles.spin : ""}`}
        >
          <img src={albumCover} alt="Album Visual" />
          <img
            className={styles.albumOverlay}
            src="https://cdn.sanity.io/images/m4jodx2s/production/8ed20b56d46d463075ac04ee6f054cfc4e81840d-2160x2160.png"
            alt="Overlay"
          />
          <div className={styles.blackHole}></div>
        </div>
        <iframe
          style={{ display: "none" }}
          title="SoundCloud Player"
          ref={iframeRef}
          src={soundCloudUrl}
          allow="autoplay"
        />
      </div>
    </div>
  );
};

export default SongSection;

import React, { useEffect, useRef, useState } from "react";
import styles from "./ActionScreen.module.scss";
import { SignupForm } from "../SignupForm/SignupForm.component";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Icon: React.FC<{ color: string }> = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-5 -5 28.93 30.62" // Adjust viewBox to include extra space
      fill={color}
      className={styles.icon} // Add a className for styling
    >
      <path
        strokeWidth="0"
        d="M0 1C0 .24.82-.24 1.48.13L18.4 9.44c.69.38.69 1.37 0 1.75L1.48 20.5A.999.999 0 010 19.62V1z"
      ></path>
    </svg>
  );
};

export const Actionscreen: React.FC<{
  translations: {
    [key: string]: string;
  };
  cover: string;
  videoLink: string;
}> = ({ translations, cover, videoLink }) => {
  const [isVisible, setIsVisible] = useState(true);
  const imageRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [checked, setChecked] = useState(false);

  const handleMusicClick = () => {
    // Track the event with Meta Pixel
    if (window.fbq) {
      window.fbq("track", "Link to music (actionscreen)", {
        buttonName: "Link to music",
      });
    }
  };

  const handleVideoClick = () => {
    // Track the event with Meta Pixel
    if (window.fbq) {
      window.fbq("track", "Link to video (actionscreen)", {
        buttonName: "Play video",
      });
    }
  };

  const { t } = useTranslation();

  const [buttonPosition, setButtonPosition] = useState(styles.fixedButton); // Use CSS module reference
  // Specify that actionScreenRef is a ref to an HTMLDivElement
  const actionScreenRef = useRef<HTMLDivElement>(null);
  // Specify that buttonRef is a ref to an HTMLButtonElement
  const buttonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (actionScreenRef.current && buttonRef.current) {
        const actionScreenBottom =
          buttonRef.current.getBoundingClientRect().bottom;
        const windowHeight = window.innerHeight;
        const buffer = 0; // Buffer of 150px from the bottom of the window

        if (actionScreenBottom <= windowHeight + buffer) {
          setButtonPosition("");
        } else {
          setButtonPosition(styles.fixedButton);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (checked) {
      inputRef.current?.focus();
      setTimeout(() => {
        inputRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 700);
    } else {
      inputRef.current?.blur();
    }
  }, [checked]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.5 } // Trigger when at least 10% of the element is in view
    );

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => observer.disconnect();
  }, []);

  // const checkIfMobile = () => {
  //   if (window.innerWidth < 1024) {
  //     return <img src="/images/awards/desktop_credits1.png" alt="" />;
  //   } else {
  //     return <img src="/images/awards/desktop_credits.png" alt="" />;
  //   }
  // };

  const handleCheckbox = () => {
    setChecked(!checked);
  };

  return (
    <div
      className={`${styles.actionScreen} ${isVisible ? styles.fadeIn : ""}`}
      ref={imageRef}
    >
      <div className={styles.topSection} ref={actionScreenRef}>
        <div className={styles.centerScreen}>
          <div className={styles.albumCover}>
            <img src={cover} alt="" />
          </div>
          <div className={styles.actionButtons}>
            <Link to={videoLink} onClick={handleVideoClick} target="_blank">
              <div className={styles.buttonWrapper} ref={buttonRef}>
                <div className={`${buttonPosition}`}>
                  <button className={`${styles.color}`}>
                    <Icon color="white" />
                    {translations.watch}
                  </button>
                </div>
              </div>
            </Link>
            <Link
              to="https://tr.ee/v4CKFeaECI"
              target="_blank"
              onClick={handleMusicClick}
            >
              <p>{t("or_listen")}:</p>
              <button>
                <img src="/images/icons/Services.png" alt="" />
              </button>
            </Link>
          </div>
          <div className={styles.signupForm}>
            <div className={styles.newsletterDropdown}>
              <label htmlFor="newsletter">{t("join")}</label>
              <input
                type="checkbox"
                id="newsletter"
                name="newsletter"
                value="newsletter"
                onChange={handleCheckbox}
              />
            </div>
            <div
              className={`${styles.signupFormWrapper} ${
                !checked ? styles.hidden : styles.visible
              }`}
            >
              <SignupForm
                inputRef={inputRef}
                compact
                subscribeButtonText={String(t("get_news"))}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.bottomSection}>
        {/* <div className={styles.credits}>
          <img src="/images/awards/desktop_credits1.png" alt="" />
        </div> */}
        <div className={styles.socialLinks}>
          <Link
            target="_blank"
            to="https://www.instagram.com/indieclimb"
            className={styles.socialLink}
          >
            <div>
              <img src="/images/icons/instagram.svg" alt="" />
            </div>
          </Link>
          <Link
            target="_blank"
            to="https://www.facebook.com/Indieclimb"
            className={styles.socialLink}
          >
            <div>
              <img src="/images/icons/facebook.svg" alt="" />
            </div>
          </Link>
          <Link
            target="_blank"
            to="https://www.tiktok.com/@indieclimb?_t=8lW96McvCnx&_r=1"
            className={styles.socialLink}
          >
            <div>
              <img src="/images/icons/tiktok.svg" alt="" />
            </div>
          </Link>
        </div>{" "}
        <div className={styles.bottomline}>
          <img src="/images/logo/Light_1024px.png" alt="" />
          <p>© 2024 INDIECLIMB RECORDS</p>
        </div>
      </div>
    </div>
  );
};
